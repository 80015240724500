import React from 'react'

import { Box, ResponsiveValue } from '@tourlane/tourlane-ui'

type Props = {
  children: any
  pt?: ResponsiveValue
}

// Each section of content in the opporunity modal needs the same vertical and horizontal spacing
export const ModalSection = ({ children, pt }: Props) => (
  <Box pb={{ xs: 24, lg: 32, xl: 24 }} px={{ xs: 16, sm: 24, md: 32 }} pt={pt}>
    {children}
  </Box>
)
