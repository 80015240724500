import React from 'react'
import { Box } from '@tourlane/tourlane-ui'

export interface SectionProps {
  children: any
  header?: React.ReactElement | string
}

// This block contain a section with a title, can contain a few cards inside
export const Section = ({ children, header, ...props }: SectionProps) => (
  <Box mb={{ xs: 24, sm: 28, md: 24, lg: 20, xl: 24 }} {...props}>
    {header && <Box mb={{ xs: 16, sm: 20, md: 16, lg: 14, xl: 16 }}>{header}</Box>}
    {children}
  </Box>
)
