import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  PropsWithChildren,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { request } from 'utils/request'
import { SCOPE, API_PREFIX, tokenManager } from 'utils/api'
import { DataType, OwnedBy } from './types'
import { transformKeys } from './utils'

interface State {
  loading: boolean
  data: DataType
  errors: string[]
  ownedBy: OwnedBy
}

interface Actions {
  actions: { toggleOwnedBy: () => void }
}

export const AppContext = createContext<(State & Actions) | undefined>(undefined)

const initialState: State = {
  loading: true,
  errors: [],
  data: {
    calls: [],
    pipeline: [],
    tasks: [],
    tripsSold: [],
  },
  ownedBy: OwnedBy.Anyone,
}

type ActionType = { type: 'updateState'; value: Partial<State> } | { type: 'toggleOwnedBy' }

const reducer = (state: State, action: ActionType) => {
  switch (action.type) {
    case 'toggleOwnedBy':
      return { ...state, ownedBy: state.ownedBy === OwnedBy.Anyone ? OwnedBy.Me : OwnedBy.Anyone }
    case 'updateState':
      return { ...state, ...action.value }
    default:
      return state
  }
}

const FETCH_INFO = [
  {
    key: 'calls',
    url: `${API_PREFIX}/agents/calls`,
  },
  {
    key: 'pipeline',
    url: `${API_PREFIX}/agents/pipeline`,
  },
  {
    key: 'tasks',
    url: `${API_PREFIX}/agents/tasks`,
  },
  {
    key: 'tripsSold',
    url: `${API_PREFIX}/trips_sold`,
  },
]

export const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  // Top-level app state.
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()

  // If the user is not authenticated, we try to log in.
  useEffect(() => {
    if (!isAuth0Loading && !isAuthenticated) {
      loginWithRedirect({
        fragment: process.env.REACT_APP_AUTH0_CLIENT_ID,
      })
    }
  }, [isAuthenticated, isAuth0Loading, loginWithRedirect])

  // Helper function that fetches all the data that the app needs.
  const fetchAllData = useCallback(async () => {
    // Get the access token.
    const accessToken = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE_SUITE_API,
      scope: SCOPE,
    })

    tokenManager.setToken(accessToken)

    // We want to show error state if any of the endpoints fails
    const errors: string[] = []

    // Fetch all the data in parallel.
    // We do this by creating an array of async functions and waiting for all of them to resolve.
    // The async functions return a [key, data] tuple for every endpoint.
    const results = await Promise.all(
      FETCH_INFO.map(async ({ key, url }) => {
        try {
          const data = await request({ url, accessToken })
          return [key, data]
        } catch (err) {
          errors.push(key)
          return [key, null]
        }
      })
    )

    dispatch({ type: 'updateState', value: { errors: errors } })

    // Merge all the data tuples into a single object.
    // Example result: { key1: data1, key2: data2 }
    // and then transform all API keys to camel case and
    // more readable names
    return transformKeys(Object.fromEntries(results))
  }, [getAccessTokenSilently])

  // Initialize app.
  useEffect(() => {
    const initializeApp = async () => {
      // If the user is not authenticated, bail out.
      if (!isAuthenticated) {
        return
      }

      // Fetch all the data and store it in the state.
      const data = await fetchAllData()
      dispatch({ type: 'updateState', value: { loading: false, data } })

      // Set interval to fetch all the data again every five minutes.
      setInterval(async () => {
        const data = await fetchAllData()
        dispatch({ type: 'updateState', value: { data } })
      }, 5 * 60 * 1000)
    }

    initializeApp()
  }, [fetchAllData, isAuthenticated])

  const toggleOwnedBy = useCallback(() => {
    dispatch({ type: 'toggleOwnedBy' })
  }, [dispatch])

  // We set the state and actions as the AppContext value.
  const contextValue = useMemo(
    () => ({
      ...state,
      actions: { toggleOwnedBy },
    }),
    [state, toggleOwnedBy]
  )
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

/**
 * Returns the AppContext value.
 */
export const useAppContext = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error(`useAppContext must be used within an AppContextProvider`)
  }
  return context
}
