import React, { SVGProps } from 'react'

import { COLORS, Flex, SvgIcon, Tooltip, useDeviceType } from '@tourlane/tourlane-ui'
import Holiday from '@tourlane/iconography/Glyphs/Suite/Holiday'
import SickLeave from '@tourlane/iconography/Glyphs/Suite/SickLeave'
import Repeater from '@tourlane/iconography/Glyphs/Suite/Repeater'
import TourlanePlus from '@tourlane/iconography/Glyphs/Suite/TourlanePlus'
import HotOpp from '@tourlane/iconography/Glyphs/Suite/HotOpp'
import WarmOpp from '@tourlane/iconography/Glyphs/Suite/WarmOpp'
import ColdOpp from '@tourlane/iconography/Glyphs/Suite/ColdOpp'
import AgentSwitch from '@tourlane/iconography/Glyphs/Suite/AgentSwitch'

import { OpportunityType } from 'components/AppContext/types'
import {
  HOT_OPP,
  COLD_OPP,
  WARM_OPP,
  NOT_CLASSIFIED,
  CUSTOMER_TYPE_TOURLANE_PLUS,
  CUSTOMER_TYPE_REPEATER,
  VACATION_LEAVE,
  SICK_LEAVE,
} from './constants'

import { StyledOppColHeader, StyledOppColName, StyledOppIcon } from './styles'

const ICON_SIZE = 20

type OpportunityProps = {
  opportunity: OpportunityType
}

type IconWithTooltipProps = {
  tooltipText: string
  Icon: React.FC<SVGProps<SVGSVGElement>>
}
const IconWithTooltip = ({ tooltipText, Icon }: IconWithTooltipProps) => {
  const { hasTouchScreen } = useDeviceType()

  return (
    <Tooltip content={tooltipText} size="small" disabled={hasTouchScreen}>
      <SvgIcon color={COLORS.ELEMENT_GRAY} colorHover={COLORS.ADVENTURE_GREEN} size={ICON_SIZE}>
        <Icon />
      </SvgIcon>
    </Tooltip>
  )
}

export const OpportunityIcons = ({ opportunity }: OpportunityProps) => {
  const {
    opportunityOwner,
    firstName,
    lastName,
    coveringUser: { coverFirstName, coverLastName, transferTypeOfLeave },
    transferredUser: { transferFirstName, transferLastName },
    leadSource: repeater,
    account: { specialCustomerType, accountOwner },
    priority: value,
  } = opportunity

  return (
    <Flex justifyContent="space-between">
      <StyledOppColHeader>
        <StyledOppColName>{firstName}</StyledOppColName>
        <StyledOppColName>{lastName}</StyledOppColName>
      </StyledOppColHeader>
      <Flex>
        {transferTypeOfLeave === VACATION_LEAVE && (
          <StyledOppIcon>
            <IconWithTooltip
              tooltipText={`Vacation transfer from: ${coverFirstName} ${coverLastName}`}
              Icon={Holiday}
            />
          </StyledOppIcon>
        )}
        {transferTypeOfLeave === SICK_LEAVE && (
          <StyledOppIcon>
            <IconWithTooltip
              tooltipText={`Sick leave transfer from: ${coverFirstName} ${coverLastName}`}
              Icon={SickLeave}
            />
          </StyledOppIcon>
        )}
        {opportunityOwner !== accountOwner && !transferTypeOfLeave && (
          <StyledOppIcon>
            <IconWithTooltip
              tooltipText={`Transfer: ${transferFirstName} ${transferLastName}`}
              Icon={AgentSwitch}
            />
          </StyledOppIcon>
        )}
        {repeater === CUSTOMER_TYPE_REPEATER &&
          specialCustomerType !== CUSTOMER_TYPE_TOURLANE_PLUS && (
            <StyledOppIcon>
              <IconWithTooltip tooltipText="Repeat customer" Icon={Repeater} />
            </StyledOppIcon>
          )}
        {specialCustomerType === CUSTOMER_TYPE_TOURLANE_PLUS && (
          <StyledOppIcon>
            <IconWithTooltip tooltipText="TourlanePlus customer" Icon={TourlanePlus} />
          </StyledOppIcon>
        )}
        {value && value !== NOT_CLASSIFIED && (
          <StyledOppIcon>
            {value === HOT_OPP && (
              <SvgIcon color={COLORS.ELEMENT_GRAY} size={ICON_SIZE}>
                <HotOpp />
              </SvgIcon>
            )}
            {value === WARM_OPP && (
              <SvgIcon color={COLORS.ELEMENT_GRAY} size={ICON_SIZE}>
                <WarmOpp />
              </SvgIcon>
            )}
            {value === COLD_OPP && (
              <SvgIcon color={COLORS.ELEMENT_GRAY} size={ICON_SIZE}>
                <ColdOpp />
              </SvgIcon>
            )}
          </StyledOppIcon>
        )}
      </Flex>
    </Flex>
  )
}
