import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import {
  Flex,
  H4,
  Base,
  Row,
  Col,
  DotWithLine,
  Small,
  Box,
  Chip,
  MEDIA,
  Icon,
  COLORS,
} from '@tourlane/tourlane-ui'
import FourZeroFour from '@tourlane/iconography/Icons/Error/FourZeroFour'

import { isInPast } from 'utils/helpers'
import { useModal } from 'hooks/useModal'
import { CallsDataType, OpportunityType } from 'components/AppContext/types'

import { useAppContext } from 'components/AppContext'
import { CALLS_HEIGHT } from 'components/PageGrid/styles'
import { Section } from 'components/Section'
import { Card } from 'components/Card'
import { OpportunityModal } from 'components/OpportunityModal'
import { useOpportunityModalContext } from 'components/OpportunityModalContext'
import { BadgeCounter } from 'components/BadgeCounter'
import { topPanelHeaderHightDesktop } from 'constants/styles'
import { OwnedByToggle } from 'components/OwnedByToggle'
import { getCurrentCalls } from 'components/AppContext/selectors'

const StyledOwnedByToggle = styled(OwnedByToggle)`
  display: none;

  ${MEDIA.only.md`
    margin-left: auto;
    display: flex;
  `}
`

const StyledFlex = styled(Flex)`
  ${MEDIA.above.md`
    min-height: ${topPanelHeaderHightDesktop};
  `}
`

const StyledInnerFlex = styled(Flex)`
  flex-grow: 1;
`

const StyledChip = styled(Chip)`
  margin-right: 16px;
  margin-left: 0;

  ${MEDIA.only.xs`
    margin-top: 8px;
    margin-bottom: 8px;
  `}

  ${MEDIA.only.sm`
    margin-top: 18px;
    margin-bottom: 18px;
  `}

  ${MEDIA.only.lg`
    margin-top: 6px;
    margin-bottom: 6px;
  `}
`

type GroupedCallsType = {
  [day: string]: CallsDataType[]
}

const CALL_TYPE: { [key: string]: { id: string; label: string } } = {
  FIRST_CALL: {
    label: 'First Call',
    id: 'first call',
  },
  OFFER_CALL: { label: 'Offer Call', id: 'offer call' },
  REVISION_CALL: { label: 'Revision Call', id: 'revision call' },
}

const getWorkingDays = () => {
  const workingDays = []

  for (let i = 0; i < 4; i++) {
    // dayjs() is today
    const day = dayjs().add(i, 'day')

    // day() at index 0 is Sunday
    // We need to show exactly 3 days, but we want to skip Sunday
    // as it is not a working day. Keep adding days until we have 3.
    if (workingDays.length < 3 && day.day() !== 0) {
      workingDays.push(day.format('ddd. DD, MMM'))
    }
  }

  return workingDays
}

const sortAndGroupByWorkingDay = (data: CallsDataType[]) => {
  // Sort ASC
  const sorted = data.sort(
    (a, b) => dayjs(a.dateAndTime).valueOf() - dayjs(b.dateAndTime).valueOf()
  )
  const workingDays = getWorkingDays()

  return workingDays.reduce((groupedCalls: GroupedCallsType, day: string) => {
    const matchingDays = sorted.filter(
      (call) => dayjs(call.dateAndTime).format('ddd. DD, MMM') === day
    )

    return { ...groupedCalls, [day]: matchingDays }
  }, {})
}

const SectionHeader = ({
  calls,
  handleFilterClick,
  activeFilters,
}: {
  calls: number
  handleFilterClick: (id: string) => void
  activeFilters: string[]
}) => (
  <StyledFlex alignItems="flex-end">
    <StyledInnerFlex
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      flexDirection={{ xs: 'ttb', sm: 'ltr' }}
    >
      <Flex alignItems="center" mb={{ xs: 8, sm: 0 }}>
        <H4>My calls</H4>
        <BadgeCounter marginLeft={{ xs: 4 }} marginRight={{ xs: 0, sm: 16 }} count={calls} />
      </Flex>

      {Boolean(calls) && (
        <Flex flexWrap="wrap" rowGap={16}>
          {Object.keys(CALL_TYPE).map((type) => {
            const callTypeValue = CALL_TYPE[type]
            return (
              <StyledChip
                onClick={() => handleFilterClick(callTypeValue.id)}
                variant={activeFilters.includes(callTypeValue.id) ? 'primary' : 'secondary'}
                noFocus
                key={callTypeValue.id}
              >
                {callTypeValue.label}
              </StyledChip>
            )
          })}
        </Flex>
      )}
      <StyledOwnedByToggle />
    </StyledInnerFlex>
  </StyledFlex>
)

const CardHeader = ({ days }: { days: string[] }) => (
  <Box py={{ xs: 16, sm: 20, xl: 16 }} px={{ xs: 16, sm: 24, md: 48, lg: 16, xl: 24 }}>
    <Row gutter={0}>
      {days.map((day, index) => (
        <Col xs={index === days.length - 1 ? false : 6} sm={4} key={day}>
          <Base strong>{day}</Base>
        </Col>
      ))}
    </Row>
  </Box>
)

export const MyCalls = () => {
  const [activeFilters, setActiveFilters] = useState<string[]>([])
  const {
    data: { calls, pipeline },
    ownedBy,
  } = useAppContext()
  const currentCalls = useMemo(() => getCurrentCalls({ data: { calls }, ownedBy }), [
    calls,
    ownedBy,
  ])
  const { isOpen, open, close } = useModal()
  const { setSelectedOpportunity } = useOpportunityModalContext()

  const callsTotal = currentCalls.length

  const callsData = useMemo<GroupedCallsType>(() => {
    if (!currentCalls || !currentCalls.length) return {}

    if (activeFilters.length) {
      // Only display calls whose type matches one of the active filters
      const filteredCallsData = currentCalls.filter((call: CallsDataType) =>
        activeFilters.includes(call.callType.toLowerCase())
      )

      return sortAndGroupByWorkingDay(filteredCallsData)
    }

    // If there are no active filters, return unchanged data from API
    return sortAndGroupByWorkingDay(currentCalls)
  }, [activeFilters, currentCalls])

  const handleFilterClick = (filterValue: string) => {
    let updatedFilters: string[] = activeFilters

    if (activeFilters.includes(filterValue)) {
      // If filter is already active, second click
      // will be to remove it
      updatedFilters = activeFilters.filter((item) => item !== filterValue)
    } else {
      updatedFilters = [...updatedFilters, filterValue]
    }

    setActiveFilters(updatedFilters)
  }

  const handleModalOpen = (opportunityId: string) => {
    // clear previously selected opp
    setSelectedOpportunity({} as OpportunityType)
    // get all the opportunity data
    const selectedOpp = pipeline.filter((opp: OpportunityType) => opp.id === opportunityId)[0]
    // set newly selected opp
    setSelectedOpportunity(selectedOpp)
    // open modal
    open()
  }

  const daysToShow = getWorkingDays()

  return (
    <>
      <Section
        header={
          <SectionHeader
            calls={callsTotal}
            handleFilterClick={handleFilterClick}
            activeFilters={activeFilters}
          />
        }
        data-testid="MyCalls"
      >
        <Card header={<CardHeader days={daysToShow} />} height={CALLS_HEIGHT} flex>
          <Box
            py={{ xs: 4, sm: 8, md: 4, lg: 2 }}
            px={{ xs: 4, sm: 12, md: 32, lg: 0 }}
            overflow="auto"
            fullHeight
          >
            {Object.keys(callsData).length ? (
              <Row gutter={0}>
                {daysToShow.map((day: string, index: number) => (
                  <Col xs={index === daysToShow.length - 1 ? false : 6} sm={4} key={index}>
                    {callsData[day].map(
                      ({ dateAndTime, salesforceId, callType, opportunity }: CallsDataType) => {
                        const callIsInPast = isInPast(dateAndTime)

                        return (
                          <DotWithLine
                            $dotColor={
                              callIsInPast ? COLORS.ELEMENT_GRAY : COLORS.NIGHTINGALE_BLACK
                            }
                            key={salesforceId}
                            data-testid={`${day}-call`}
                            clickable
                            onClick={() => handleModalOpen(opportunity.salesforceId)}
                          >
                            <Small muted={callIsInPast} ellipsis bold>
                              {dayjs(dateAndTime).format('H:mm')}
                            </Small>

                            <Small muted={callIsInPast} ellipsis bold>
                              {callType}
                            </Small>

                            <Small muted ellipsis>
                              {`${opportunity.firstName} ${opportunity.lastName}`}
                            </Small>
                          </DotWithLine>
                        )
                      }
                    )}
                  </Col>
                ))}
              </Row>
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                fullHeight
                paddingTop={20}
                paddingHorizontal={20}
                paddingBottom={40}
              >
                <Box mb={20}>
                  <Icon size={{ xs: 130, sm: 200 }}>
                    <FourZeroFour />
                  </Icon>
                </Box>

                <Base textAlignCenter>
                  You have no calls scheduled for the next 3 working days.
                </Base>
              </Flex>
            )}
          </Box>
        </Card>
      </Section>
      <OpportunityModal isOpen={isOpen} close={close} />
    </>
  )
}
