import React from 'react'
import { Box, H4, Card, Skeleton } from '@tourlane/tourlane-ui'

import { ErrorState } from 'components/ErrorState'
import { Section } from '../Section'

type GridItemWrapperProps = {
  children: any
  isLoading: boolean
  isError: boolean
  height: Record<string, number | string>
  title?: string
  size: string
  isCompanyData?: boolean
}

const Header = ({ title }: { title?: string }) => (
  <Box mb={{ xs: 8, sm: 0 }}>
    <H4>{title}</H4>
  </Box>
)

export const PageGridItem = ({
  children,
  isLoading,
  isError,
  height,
  title,
  size,
  isCompanyData,
}: GridItemWrapperProps) => {
  if (isLoading)
    return (
      <Section header={title && <Header title={title} />}>
        <Skeleton height={height} />
      </Section>
    )

  if (isError)
    return (
      <Section header={title && <Header title={title} />}>
        <Card>
          <Box height={height}>
            <ErrorState size={size} isCompanyData={isCompanyData} />
          </Box>
        </Card>
      </Section>
    )

  return <Box fullHeight>{children}</Box>
}
