import { CallsDataType, OpportunityType, OwnedBy, TaskType } from './types'
import { filterEntitiesWithoutCoveringUser } from './utils'

export const getOwnedByAnyoneFlag = (state: { ownedBy?: OwnedBy }): boolean =>
  !!state?.ownedBy && state.ownedBy === OwnedBy.Anyone

export const getCurrentCalls = (state: {
  data?: { calls?: CallsDataType[] }
  ownedBy?: OwnedBy
}): CallsDataType[] => {
  if (!state?.data?.calls?.length) {
    return []
  }

  const { calls } = state.data
  if (getOwnedByAnyoneFlag(state)) {
    return calls
  }

  return filterEntitiesWithoutCoveringUser(calls)
}

export const getCurrentPipeline = (state: {
  data?: { pipeline?: OpportunityType[] }
  ownedBy?: OwnedBy
}): OpportunityType[] => {
  if (!state?.data?.pipeline?.length) {
    return []
  }

  const { pipeline } = state.data
  if (getOwnedByAnyoneFlag(state)) {
    return pipeline
  }

  return filterEntitiesWithoutCoveringUser(pipeline)
}

export const getCurrentTasks = (state: {
  data?: { tasks?: TaskType[] }
  ownedBy?: OwnedBy
}): TaskType[] => {
  if (!state?.data?.tasks?.length) {
    return []
  }

  const { tasks } = state.data
  if (getOwnedByAnyoneFlag(state)) {
    return tasks
  }

  return filterEntitiesWithoutCoveringUser(tasks)
}
