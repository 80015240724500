import React, { useState } from 'react'

import { COLORS, Flex, SvgIcon } from '@tourlane/tourlane-ui'
import NoShow from '@tourlane/iconography/Glyphs/Suite/NoShow'
import HotOpp from '@tourlane/iconography/Glyphs/Suite/HotOpp'
import WarmOpp from '@tourlane/iconography/Glyphs/Suite/WarmOpp'
import ColdOpp from '@tourlane/iconography/Glyphs/Suite/ColdOpp'

import { Header } from 'components/Header'
import { COLD_OPP, HOT_OPP, WARM_OPP, NO_SHOW_ID } from './constants'

import { StyledFiltersChip, StyledFiltersWrapper } from './styles'

const FILTERS = [
  { icon: NoShow, label: 'No shows', id: NO_SHOW_ID },
  { icon: HotOpp, label: 'Hot opp', id: HOT_OPP },
  { icon: WarmOpp, label: 'Warm opp', id: WARM_OPP },
  { icon: ColdOpp, label: 'Cold opp', id: COLD_OPP },
]

type FilterProps = {
  updateFilters: (filters: string[]) => void
  itemsCount: number
}
export const Filter = ({ updateFilters, itemsCount }: FilterProps) => {
  const [selected, setSelected] = useState<string[]>([])

  const onSelect = (id: string) => {
    let updatedFilters

    if (selected.includes(id)) {
      updatedFilters = selected.filter((item: string) => item !== id)
    } else {
      updatedFilters = [...selected, id]
    }

    setSelected(updatedFilters)
    updateFilters(updatedFilters)
  }

  return (
    <Flex alignItems="center" wrap="wrap">
      <Header count={itemsCount}>My pipeline</Header>
      {Boolean(itemsCount) && (
        <StyledFiltersWrapper wrap={true}>
          {FILTERS.map(({ id, icon: Icon, label }) => (
            <StyledFiltersChip
              variant={selected.includes(id) ? 'primary' : 'secondary'}
              clickable
              noFocus
              iconLeft={<SvgIcon color={COLORS.ELEMENT_GRAY}>{<Icon />}</SvgIcon>}
              onClick={() => onSelect(id)}
              key={id}
            >
              {label}
            </StyledFiltersChip>
          ))}
        </StyledFiltersWrapper>
      )}
    </Flex>
  )
}
