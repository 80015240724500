import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'
import { Box, H5, Big, Base, Flex, Hr } from '@tourlane/tourlane-ui'

import DestinationConfirmedIcon from '@tourlane/iconography/Icons/Suite/DestinationConfirmed'
import DepartureDateIcon from '@tourlane/iconography/Icons/Suite/DepartureDate'
import BudgetMetIcon from '@tourlane/iconography/Icons/Suite/BudgetMet'
import MarketingReferralIcon from '@tourlane/iconography/Icons/Suite/MarketingReferral'

import { useOpportunityModalContext } from 'components/OpportunityModalContext'
import { PRESENT_SERVICE, SEND_OFFER } from 'components/Pipeline/constants'
import { ModalSection } from './ModalSection'
import { StyledLeadQualIcon, StyledLeadQualFlex } from './styles'

const StyledWrappedText = styled.div`white-space: pre-wrap;`

type QuestionAnswerType = { [key in 'question' | 'answer']: string }[]

type AnswersType = {
  agentNotes: QuestionAnswerType
  leadQualQandA: QuestionAnswerType
  EEQandA: QuestionAnswerType
}

const formattedQuestions: { [key: string]: string } = {
  'Does the customer still want to go to the destination he entered on the website? ':
    'Travel destination confirmed?',
  'Is the time to the intended departure greater than 4 weeks? ': 'Departure in 4 weeks or more?',
  'Has the customer the required minimum Budget? ': 'Required minimum budget met?',
  'How did the customer hear about us? ': 'How did they hear about us?',
}

const formatQuestions = (leadQualData: QuestionAnswerType) => {
  return leadQualData.map(({ question, answer }) => {
    // If we have text for a question that fits the layout better, swap it out
    // otherwise stick with the question wording as it is from the BE
    const updatedQuestion = Object.keys(formattedQuestions).includes(question)
      ? formattedQuestions[question]
      : question

    return { question: updatedQuestion, answer }
  })
}

const groupQandA = (parsedQandA: string[]) => {
  // parsedQandA is an array of strings in the order of ["question", "answer", "question"] etc
  // so, even index values and 0 are questions, odd index values are answers
  return parsedQandA.reduce((allItems: QuestionAnswerType, currItem: string, index: number) => {
    if (index % 2 === 0) {
      return [...allItems, { question: currItem, answer: parsedQandA[index + 1] }]
    }

    return allItems
  }, [])
}

export const OpportunityModalCustomerInfo = () => {
  const [content, setContent] = useState<AnswersType>({
    agentNotes: [],
    leadQualQandA: [],
    EEQandA: [],
  })

  const {
    selectedOpportunity: {
      answers,
      callNotesCustomer,
      callNotesTrip,
      docHandlingComments,
      revisionNotes,
      internalComments,
      stage,
    },
  } = useOpportunityModalContext()

  useEffect(() => {
    if (answers) {
      const parsedQandA = (parse(answers) as JSX.Element[])
        .map((item) => {
          // If item is a string, it has already been parsed
          // otherwise, it is a child node and we need to extract the
          // string from that
          if (typeof item !== 'string') {
            const {
              props: { children },
            } = item

            // If the child is a string, add it to the array of strings,
            if (children && children.length && typeof children === 'string') {
              return children
            }

            // Some children are either null or empty arrays which we
            // filter out at the next step
            return null
          }

          return item
        })
        .filter((item) => item !== null) as string[]

      const updatedAnswers = groupQandA(parsedQandA)

      const findFirstQuestionIndex = (questionString: string) => {
        return updatedAnswers.findIndex(({ question }) => question === questionString)
      }

      // These are the first questions / items for the agent notes section and the lead
      // qualification section of the HTML so we use these to identify the start of each of these
      // blocks. It's not ideal as the questions could change, but it was suggested that this was unlikely.
      // https://tourlane.slack.com/archives/G0173FZL5G9/p1613123133003800?thread_ts=1612971227.017500&cid=G0173FZL5G9
      const questions = {
        agentNotesFirstQuestion: 'Comments for the Travel Expert. ',
        leadQualFirstQuestion:
          'Does the customer still want to go to the destination he entered on the website? ',
      }

      // We know that there is one question answer pair for
      // the agent notes section, so we remove these from updatedAnswers
      const agentNotes =
        findFirstQuestionIndex(questions.agentNotesFirstQuestion) !== -1
          ? updatedAnswers.splice(findFirstQuestionIndex(questions.agentNotesFirstQuestion), 1)
          : []

      // We know there are 4 questions and 4 answers from
      // the lead qualification tool, so we remove these from updatedAnswers
      let leadQualQandA =
        findFirstQuestionIndex(questions.leadQualFirstQuestion) !== -1
          ? updatedAnswers.splice(findFirstQuestionIndex(questions.leadQualFirstQuestion), 4)
          : []

      leadQualQandA = formatQuestions(leadQualQandA)

      // The number of questions and answers from the EE can vary due to AB testing
      // etc, so once we have removed agent notes and LQ, we return everything else
      // that is left from updatedAnswers
      const EEQandA = updatedAnswers

      setContent({ agentNotes, leadQualQandA, EEQandA })
    }
  }, [answers])

  const icons = [
    <DestinationConfirmedIcon />,
    <DepartureDateIcon />,
    <BudgetMetIcon />,
    <MarketingReferralIcon />,
  ]

  const { agentNotes, leadQualQandA, EEQandA } = content

  const shouldShowCustomerInfo = Boolean(
    leadQualQandA.length ||
      agentNotes.length ||
      callNotesCustomer ||
      callNotesTrip ||
      docHandlingComments ||
      revisionNotes ||
      internalComments
  )

  const shouldShowNotes = Boolean(
    agentNotes.length ||
      callNotesCustomer ||
      callNotesTrip ||
      docHandlingComments ||
      revisionNotes ||
      internalComments
  )

  return (
    <>
      {shouldShowCustomerInfo && (
        <ModalSection>
          <Hr mb={{ xs: 24, lg: 32 }} />
          <H5>Customer Information</H5>
          {Boolean(leadQualQandA.length) && (stage === PRESENT_SERVICE || stage === SEND_OFFER) && (
            <Box mt={16}>
              <Big bold>Qualification answers</Big>
              <Flex
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent={{ xs: 'space-around', md: 'flex-start', lg: 'space-around' }}
                alignItems={{ xs: 'center', md: 'flex-start' }}
                flexWrap={{ xs: 'wrap', md: 'nowrap', lg: 'wrap' }}
                fullWidth
                mt={{ xs: 32, sm: 24 }}
              >
                {leadQualQandA.map(({ question, answer }, index) => (
                  <StyledLeadQualFlex
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    key={question}
                    fullHeight
                  >
                    <StyledLeadQualIcon size={96}>{icons[index]}</StyledLeadQualIcon>
                    <Base textAlignCenter bold>
                      {question}
                    </Base>
                    <Base textAlignCenter>{answer}</Base>
                  </StyledLeadQualFlex>
                ))}
              </Flex>
            </Box>
          )}

          {shouldShowNotes && (
            <Flex flexDirection="column" columnGap={16} mt={24}>
              <Big bold>Notes</Big>

              {Boolean(agentNotes.length) && (
                <div>
                  <Base bold>{content?.agentNotes[0].question}</Base>
                  <Base>{content?.agentNotes[0].answer}</Base>
                </div>
              )}

              {internalComments && (
                <div>
                  <Base bold>Internal notes</Base>
                  <Base>{internalComments}</Base>
                </div>
              )}

              {docHandlingComments && (
                <div>
                  <Base bold>Doc Handling notes</Base>
                  <Base>{docHandlingComments}</Base>
                </div>
              )}

              {callNotesCustomer && (
                <div>
                  <Base bold>Customer notes</Base>
                  <StyledWrappedText><Base>{callNotesCustomer}</Base></StyledWrappedText>
                </div>
              )}

              {callNotesTrip && (
                <div>
                  <Base bold>Trip notes</Base>
                  <StyledWrappedText><Base>{callNotesTrip}</Base></StyledWrappedText>
                </div>
              )}

              {revisionNotes && (
                <div>
                  <Base bold>Revision notes</Base>
                  <StyledWrappedText><Base>{revisionNotes}</Base></StyledWrappedText>
                </div>
              )}
            </Flex>
          )}
        </ModalSection>
      )}

      {Boolean(EEQandA.length) && (stage === PRESENT_SERVICE || stage === SEND_OFFER) && (
        <ModalSection>
          <Hr mb={{ xs: 24, lg: 32 }} />

          <H5>Enquiry Experience Answers</H5>

          {EEQandA.map(({ question, answer }) => (
            <Box key={question} mt={16}>
              <Base>{question}</Base>
              <Base bold>{answer}</Base>
            </Box>
          ))}
        </ModalSection>
      )}
    </>
  )
}
