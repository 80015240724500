import React, { useMemo } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import {
  H4,
  ListItemLink,
  Chip,
  COLORS,
  IconDefault,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  Box,
  Base,
  Icon,
  Flex,
  MEDIA,
} from '@tourlane/tourlane-ui'
import AlertIcon from '@tourlane/iconography/Glyphs/Notifications/Alert'
import FourZeroFour from '@tourlane/iconography/Icons/Error/FourZeroFour'

import { isInPast } from 'utils/helpers'
import { TaskType } from 'components/AppContext/types'

import { useAppContext } from 'components/AppContext'
import { MY_DAY_HEIGHT } from 'components/PageGrid/styles'
import { Section } from 'components/Section'
import { Card } from 'components/Card'
import { BadgeCounter } from 'components/BadgeCounter'
import { topPanelHeaderHightDesktop } from 'constants/styles'
import { OwnedByToggle } from 'components/OwnedByToggle'
import { getCurrentTasks } from 'components/AppContext/selectors'

dayjs.extend(isSameOrBefore)

const TASKS_NAME = 'tasks' as string
const TASKS_TYPE = 'internal'
const REMINDERS_NAME = 'reminders' as string
const REMINDERS_TYPE = 'reminder'

const TABS = [
  {
    name: TASKS_NAME,
    label: 'Tasks',
    type: TASKS_TYPE,
  },
  {
    name: REMINDERS_NAME,
    label: 'Reminders',
    type: REMINDERS_TYPE,
  },
]

const StyledOwnedByToggle = styled(OwnedByToggle)`
  margin-left: auto;

  ${MEDIA.to.md`
    display: none;
  `}
`

const StyledFlex = styled(Flex)`
  ${MEDIA.above.md`
    min-height: ${topPanelHeaderHightDesktop};
  `}
`

const StyledListItemLink = styled(ListItemLink)`
  width: 100%;
`

const StyledTabPanel = styled(TabPanel)`
  flex-grow: 1;
`

const sortTasksByDueDate = (tasks: TaskType[]) => {
  // Create array of tasks with no dueDate to be ordered after the sorted tasks.
  // This is an edge case and only true and possible for tasks and reminders
  // created before the dueDate field was implemented on the BE.
  const tasksWithoutDueDate = tasks.filter(({ dueDate }) => !dueDate)

  // We can only sort tasks that have a dueDate
  // otherwise the compare function returns undefined
  const sortedTasksWithDueDate = tasks
    // We only want to have the tasks and reminders due today or in past
    .filter(({ dueDate }) => dueDate && dayjs(dueDate).isSameOrBefore(dayjs(), 'day'))
    .sort((a, b) => dayjs(a.dueDate).valueOf() - dayjs(b.dueDate).valueOf())

  return [...sortedTasksWithDueDate, ...tasksWithoutDueDate]
}

const SectionHeader = ({ count }: { count?: number }) => (
  <StyledFlex alignItems="flex-end">
    <Flex alignItems="center">
      <H4>My day</H4>
      {Boolean(count) && (
        <BadgeCounter marginLeft={{ xs: 4 }} marginRight={{ xs: 0, sm: 16 }} count={count} />
      )}
    </Flex>
    <StyledOwnedByToggle />
  </StyledFlex>
)

const CardHeader = ({ tasks }: { tasks: TaskType[] }) => (
  <TabList label="Section" showBackdrops={false} paddingHorizontal={0} fullHeight>
    {TABS.map((tab) => {
      const tasksCount = tasks && tasks.filter((task) => task.recordType === tab.type).length

      return (
        <Tab
          name={tab.name}
          key={tab.name}
          paddingTop={{ xs: 16, sm: 20, md: 24, lg: 20, xl: 24 }}
          paddingInnerHorizontal={{ xs: 16, sm: 12, xl: 16 }}
          paddingOuterHorizontal={{ xs: 16, sm: 16, lg: 20, xl: 24 }}
        >
          <Flex alignItems="center" justifyContent="center" fullWidth>
            {tab.label}
            {Boolean(tasks.length) && <BadgeCounter count={tasksCount} marginLeft={4} />}
          </Flex>
        </Tab>
      )
    })}
  </TabList>
)

export const MyDay = () => {
  const {
    data: { tasks },
    ownedBy,
  } = useAppContext()
  const currentTasks = useMemo(() => getCurrentTasks({ data: { tasks }, ownedBy }), [
    tasks,
    ownedBy,
  ])
  const sortedItems = sortTasksByDueDate(currentTasks)

  const itemsMap = {
    [TASKS_NAME]: sortedItems.filter((item) => item.recordType === TASKS_TYPE),
    [REMINDERS_NAME]: sortedItems.filter((item) => item.recordType === REMINDERS_TYPE),
  }

  return (
    <Section header={<SectionHeader count={sortedItems.length} />} data-testid="MyDay">
      <Tabs selected="tasks">
        <Card header={<CardHeader tasks={sortedItems} />} flex height={MY_DAY_HEIGHT}>
          <Box py={{ xs: 4, sm: 8, md: 4, lg: 2 }} overflow="auto" fullHeight>
            <Flex flexDirection="column" fullHeight>
              {TABS.map((tab) => {
                const tabItems = itemsMap[tab.name]
                return (
                  <StyledTabPanel name={tab.name} key={tab.name}>
                    {tabItems.length ? (
                      tabItems.map(({ salesforceId, subject, dueDate }: TaskType) => (
                        <StyledListItemLink
                          key={salesforceId}
                          title={subject}
                          chip={
                            isInPast(dueDate) && (
                              <Chip
                                iconLeft={<AlertIcon />}
                                iconLeftColor={COLORS.RIOJA_RED}
                                iconLeftSize={24}
                              >
                                Overdue
                              </Chip>
                            )
                          }
                          icon={
                            isInPast(dueDate) && (
                              <IconDefault
                                icon={<AlertIcon />}
                                color={COLORS.RIOJA_RED}
                                colorHover={COLORS.RIOJA_RED_FOCUSED}
                              />
                            )
                          }
                          href={`${process.env.REACT_APP_TOURLANE_SUITE_SF_URL}/s/task/${salesforceId}`}
                        />
                      ))
                    ) : (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        fullHeight
                        paddingHorizontal={20}
                        paddingBottom={20}
                        paddingTop={40}
                      >
                        <Icon size={{ xs: 100, sm: 200 }}>
                          <FourZeroFour />
                        </Icon>

                        <Base
                          withTopMargin
                          textAlignCenter
                        >{`You don't have any ${tab.name} for today.`}</Base>
                      </Flex>
                    )}
                  </StyledTabPanel>
                )
              })}
            </Flex>
          </Box>
        </Card>
      </Tabs>
    </Section>
  )
}
