import React from 'react'

import { ProgressBar, Box, H5, COLORS, Icon, Base } from '@tourlane/tourlane-ui'
import PresentServiceIcon from '@tourlane/iconography/Glyphs/Suite/PresentService'
import SendOfferIcon from '@tourlane/iconography/Glyphs/Suite/SendOffer'
import ReceiveConfirmationIcon from '@tourlane/iconography/Glyphs/Suite/ReceiveConfirmation'
import SupplyPendingIcon from '@tourlane/iconography/Glyphs/Suite/SupplyPending'
import CheckmarkIcon from '@tourlane/iconography/Glyphs/Other/Checkmark'

import { STAGE_TYPES } from 'components/Pipeline/normalize'
import { useOpportunityModalContext } from 'components/OpportunityModalContext'
import { StyledFlex, StyledWrapper, StyledTextWrapper } from './styles'

export const OpportunityModalProgressBar = () => {
  const {
    selectedOpportunity: { stage },
  } = useOpportunityModalContext()

  const TYPES = [...STAGE_TYPES, { stageId: 'Supply Confirmed', title: 'Supply Confirmed' }]
  const icons = [
    <PresentServiceIcon />,
    <SendOfferIcon />,
    <ReceiveConfirmationIcon />,
    <SupplyPendingIcon />,
    <CheckmarkIcon />,
  ]

  const currentStageIndex = TYPES.findIndex(({ stageId }) => stageId === stage)
  const MAX_STAGES = 4
  const currentProgress = (currentStageIndex / MAX_STAGES) * 100

  return (
    <>
      <H5>Progress</H5>

      <Box mt={{ xs: 25, sm: 35, lg: 43, xl: 35 }} mb={{ xs: 16, sm: 24, lg: 32, xl: 24 }}>
        <Box
          px={{ xs: 0, sm: 24, md: 32 }}
          height={{ xs: 24, sm: 30 }}
          fullWidth
          position="relative"
        >
          <StyledFlex fullWidth fullHeight justifyContent="space-between" px="inherit">
            {icons.map((icon, index) => (
              <StyledWrapper
                alignItems="center"
                justifyContent="center"
                key={TYPES[index].title}
                $isActive={index <= currentStageIndex}
              >
                <Icon
                  size={{ xs: 16, sm: 20 }}
                  color={index <= currentStageIndex ? COLORS.SENSATION_WHITE : COLORS.ELEMENT_GRAY}
                >
                  {icon}
                </Icon>
              </StyledWrapper>
            ))}
          </StyledFlex>

          <ProgressBar progress={currentProgress} height={{ xs: 6, sm: 8 }} />
        </Box>

        <StyledTextWrapper fullWidth justifyContent="space-between">
          {TYPES.map(({ title }, index) => (
            <Box width={{ xs: 0, sm: 88, md: 98, xl: 110 }} key={title}>
              <Base textAlignCenter strong={index === currentStageIndex}>
                {title}
              </Base>
            </Box>
          ))}
        </StyledTextWrapper>
      </Box>
    </>
  )
}
