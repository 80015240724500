import React from 'react'
import { Flex, H4 } from '@tourlane/tourlane-ui'

import { BadgeCounter } from 'components/BadgeCounter'

type HeaderProps = {
  children: any
  count?: number
}

export const Header = ({ children, count }: HeaderProps) => {
  return (
    <Flex alignItems="center">
      <H4>{children}</H4>
      {Boolean(count) && <BadgeCounter count={count} />}
    </Flex>
  )
}
