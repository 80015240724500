import React from 'react'
import styled from 'styled-components'

import { Box, Row, Col, MEDIA } from '@tourlane/tourlane-ui'

import { useAppContext } from 'components/AppContext'
import { Pipeline } from 'components/Pipeline'
import { MyCalls } from 'components/MyCalls'
import { MyDay } from 'components/MyDay'
import { OwnedByToggle } from 'components/OwnedByToggle'
import { PageGridItem } from './PageGridItem'

import {
  CALLS_HEIGHT,
  MY_DAY_HEIGHT,
  PIPELINE_HEIGHT,
} from './styles'

const StyledRow = styled(Row)`
  display: none;
  margin-bottom: 8px;

  ${MEDIA.to.sm`
    display: block;
  `}
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const PageGrid = () => {
  const { loading, errors } = useAppContext()

  return (
    <Box
      mt={{ xs: 26, sm: 16, md: 24, lg: 44 }}
      ml={{ xs: 16, md: 24, lg: 100, xl: 144 }}
      mr={{ xs: 16, md: 24, lg: 30, xl: 64 }}
    >
      <StyledRow gutter={{ md: 20, lg: 24 }}>
        <StyledCol>
          <OwnedByToggle />
        </StyledCol>
      </StyledRow>
      <Row gutter={{ md: 20, lg: 24 }}>
        <Col xs={12} lg={6}>
          <PageGridItem
            isError={errors.includes('calls')}
            height={CALLS_HEIGHT}
            title="My calls"
            size="md"
            isLoading={loading}
          >
            <MyCalls />
          </PageGridItem>
        </Col>
        <Col xs={12} lg={6}>
          <PageGridItem
            isError={errors.includes('tasks')}
            height={MY_DAY_HEIGHT}
            title="My day"
            size="md"
            isLoading={loading}
          >
            <MyDay />
          </PageGridItem>
        </Col>
        <Col xs={12}>
          <PageGridItem
            isError={errors.includes('pipeline')}
            height={PIPELINE_HEIGHT}
            title="My pipeline"
            size="md"
            isLoading={loading}
          >
            <Pipeline />
          </PageGridItem>
        </Col>
      </Row>
    </Box>
  )
}
