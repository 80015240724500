import dayjs from 'dayjs'
import { OpportunityType } from 'components/AppContext/types'
import {
  NO_SHOW_ID,
  PRESENT_SERVICE,
  SEND_OFFER,
  RECEIVE_CONFIRMATION,
  SUPPLY_PENDING,
  INDICATOR_GOOD,
  INDICATOR_MODERATE,
  INDICATOR_CRITICAL,
} from './constants'

const filterPipelineOpportunities = (
  opportunities: OpportunityType[],
  filtrationParams: string[]
) => {
  let filteredOpportunities: OpportunityType[] = []

  filtrationParams?.forEach((param) => {
    const filteredByParam = opportunities.filter((opportunity) => {
      const matchesFilter =
        // We filter items by 2 parameters: no show calls (no_show_call) and priority (priority__c)
        param === NO_SHOW_ID ? opportunity.isNoShowCall : opportunity.priority === param

      // As one item can have both priority and no show key values, we need to exclude duplicates
      const isDuplicate = filteredOpportunities.find((item) => item.id === opportunity.id)
      return matchesFilter && !isDuplicate
    })

    filteredOpportunities = [...filteredOpportunities, ...filteredByParam]
  })
  return filteredOpportunities
}

type StageOpportunitiesType = {
  [stageType: string]: {
    items: OpportunityType[]
    count: number
    totalPrice: number
    stage: string
  }
}

export const setIndicator = (opportunity: OpportunityType, today: any) => {
  const { stageSince, totalCallAttempts, offerCallTime, firstCallTime, stage } = opportunity
  const daysInStage = stageSince ? today.diff(dayjs(stageSince), 'day') : 0
  const timeToOfferCall = offerCallTime ? dayjs(offerCallTime).diff(today, 'hour') : 0
  const timeAfterFirstCall = firstCallTime ? today.diff(dayjs(firstCallTime), 'hour') : 0
  const callAttempts = totalCallAttempts ?? 0

  if (stage === PRESENT_SERVICE) {
    if (callAttempts > 8 || daysInStage > 10) return INDICATOR_CRITICAL
    if (callAttempts >= 4 || daysInStage >= 5) return INDICATOR_MODERATE
    return INDICATOR_GOOD
  }

  if (stage === SEND_OFFER) {
    if (timeToOfferCall < 24 || timeAfterFirstCall > 96) return INDICATOR_CRITICAL
    if (timeToOfferCall <= 48 || timeAfterFirstCall >= 48) return INDICATOR_MODERATE
    return INDICATOR_GOOD
  }

  if (stage === RECEIVE_CONFIRMATION) {
    if (callAttempts > 7 || daysInStage > 14) return INDICATOR_CRITICAL
    if (callAttempts >= 4 || daysInStage >= 7) return INDICATOR_MODERATE
    return INDICATOR_GOOD
  }

  if (stage === SUPPLY_PENDING) {
    if (daysInStage < 14) return INDICATOR_GOOD
    if (daysInStage <= 21) return INDICATOR_MODERATE
    return INDICATOR_CRITICAL
  }

  return INDICATOR_GOOD
}

// Stages types, id — stage values in API, title — UI version
export const STAGE_TYPES = [
  { stageId: 'Present Service', title: 'Present Service' },
  { stageId: 'Send Offer', title: 'Send Offer' },
  { stageId: 'Receive Confirmation', title: 'Receive Confirmation' },
  { stageId: 'Confirmed - Supply Pending', title: 'Supply Pending' },
]

export const normalizePipelineOffers = (
  opportunities: OpportunityType[],
  filtrationParams: string[]
) => {
  const sortedByStageOpportunities: StageOpportunitiesType = {}

  // Sort items by stage and create an object where each key represents a stage
  STAGE_TYPES.forEach(({ stageId, title }) => {
    let totalStagePrice = 0
    let sortedItems = opportunities.filter(({ stage }) => stage === stageId)

    // We need to sort into 3 groups: items that have next call time, items that have no call time
    // but have departure time and items that have none of the two options
    const itemsWithNoTime: OpportunityType[] = []
    let itemsWithDepartureDate: OpportunityType[] = []
    let itemsWithCallTime: OpportunityType[] = []
    sortedItems.forEach((item) => {
      item.indicator = setIndicator(item, dayjs())
      if (item.nextCallTime) {
        itemsWithCallTime.push(item)

        // Sort by next call time: soonest are at the top. We compare them by timestamp (valueOf())
        itemsWithCallTime.sort(
          (a, b) => dayjs(a.nextCallTime).valueOf() - dayjs(b.nextCallTime).valueOf()
        )
      } else if (!item.nextCallTime && item.activeOffer.departureDate) {
        itemsWithDepartureDate.push(item)

        // Sort by departure date time: soonest are at the top. We compare them by timestamp (valueOf())
        itemsWithDepartureDate.sort(
          (a, b) =>
            // @ts-ignore we can ignore the item may be null warning
            // as we already have a check on line 112
            dayjs(a.activeOffer.departureDate).valueOf() -
            // @ts-ignore
            dayjs(b.activeOffer.departureDate).valueOf()
        )
      } else {
        itemsWithNoTime.push(item)
      }
    })

    sortedItems = [...itemsWithCallTime, ...itemsWithDepartureDate, ...itemsWithNoTime]

    // Calculate the total price for the stage
    sortedItems.forEach(({ price }) => {
      if (price) totalStagePrice += Number(price)
    })

    // If we have filtrationParams passed we want to do the filtration as the last step
    // We do filtration in normalization so that when the endpoint refreshes we can normalize and
    // filter the data if there are any filters
    sortedByStageOpportunities[title] = {
      items: filtrationParams.length
        ? filterPipelineOpportunities(sortedItems, filtrationParams)
        : sortedItems,
      count: sortedItems.length,
      totalPrice: totalStagePrice,
      stage: stageId,
    }
  })

  return sortedByStageOpportunities
}
