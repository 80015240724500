import type { DataType, CoveringUser } from './types'

type DataKeysMap = {
  [key: string]: { [key: string]: string }
}

const dataKeysMap: DataKeysMap = {
  calls: {
    salesforce_id: 'salesforceId',
    call_type__c: 'callType',
    date_and_time__c: 'dateAndTime',
    status__c: 'status',
    ownerid: 'ownerId',
    opportunity: 'opportunity',
    covering_user: 'coveringUser',
    transferred_user: 'transferredUser',
  },
  opportunity: {
    name: 'internalName',
    account_first_name__c: 'firstName',
    account_last_name__c: 'lastName',
    salesforce_id: 'salesforceId',
  },
  pipeline: {
    account_first_name__c: 'firstName',
    account_last_name__c: 'lastName',
    answers__c: 'answers',
    call_notes_customer__c: 'callNotesCustomer',
    call_notes_trip__c: 'callNotesTrip',
    comment__c: 'internalComments',
    departure_date__c: 'departureDate',
    destination__c: 'destination',
    doc_handling_comment__c: 'docHandlingComments',
    first_call_time: 'firstCallTime',
    id: 'id',
    latest_call_attempt__c: 'latestCallAttempt',
    leadsource: 'leadSource',
    name: 'name',
    next_call_customer__c: 'nextCallTime',
    next_call_type__c: 'nextCallType',
    no_show_call: 'isNoShowCall',
    offer_call_time: 'offerCallTime',
    ownerid: 'opportunityOwner',
    pre_confirmed__c: 'isPreConfirmed',
    priority__c: 'priority',
    revision_notes__c: 'revisionNotes',
    stage_since: 'stageSince',
    stagename: 'stage',
    total_amount__c: 'price',
    total_call_attempts__c: 'totalCallAttempts',
    account: 'account',
    active_offer: 'activeOffer',
    covering_user: 'coveringUser',
    transferred_user: 'transferredUser',
    preview_id__c: 'offerPreviewId',
    offer_preview_link__c: 'offerPreviewLink',
  },
  account: {
    ownerid: 'accountOwner',
    phone: 'phoneNumber',
    special_customer_type__c: 'specialCustomerType',
  },
  active_offer: {
    bc_url__c: 'tripPlannerUrl',
    offer_margin__c: 'offerMargin',
    iternary_wetu_link__c: 'internalWetuLink',
    pax_count__c: 'paxCount',
    departure_date__c: 'departureDate',
    arrival_date__c: 'arrivalDate',
  },
  covering_user: {
    firstname: 'coverFirstName',
    lastname: 'coverLastName',
    salesforce_id: 'salesforceId',
    type_of_leave__c: 'transferTypeOfLeave',
    username: 'userName',
  },
  transferred_user: {
    firstname: 'transferFirstName',
    lastname: 'transferLastName',
    username: 'userName',
    salesforce_id: 'salesforceId',
  },
  tasks: {
    activitydate: 'activityDate',
    due_date__c: 'dueDate',
    ownerid: 'ownerId',
    priority: 'priority',
    record_type: 'recordType',
    salesforce_id: 'salesforceId',
    status: 'status',
    subject: 'subject',
    covering_user: 'coveringUser',
    transferred_user: 'transferredUser',
  },
  day: {
    booking_value: 'bookingValue',
    name: 'name',
    period_end: 'periodEnd',
    period_start: 'periodStart',
    target_achieved: 'targetAchieved',
    target_booking_value: 'targetBookingValue',
  },
  month: {
    booking_value: 'bookingValue',
    name: 'name',
    period_end: 'periodEnd',
    period_start: 'periodStart',
    target_achieved: 'targetAchieved',
    target_booking_value: 'targetBookingValue',
  },
  week: {
    booking_value: 'bookingValue',
    name: 'name',
    period_end: 'periodEnd',
    period_start: 'periodStart',
    target_achieved: 'targetAchieved',
    target_booking_value: 'targetBookingValue',
  },
  user: {
    salesforce_id: 'salesforceId',
    firstname: 'firstName',
    lastname: 'lastName',
  },
  tripsSold: {
    salesforce_id: 'salesforceId',
    destination__c: 'destination',
    sum_prices_eur__c: 'price',
    createddate: 'dateCreated',
    user: 'user',
  },
}

export type FieldType = {
  [key: string]: number | boolean | string | FieldType
}

export const transformObjectKeys = (
  endpointKey: string,
  endpointResults: FieldType
): FieldType | null => {
  if (!endpointResults) return null

  // When the value passed to the recursive call is an array, return the array as value to `transformKeys`
  if (Array.isArray(endpointResults)) {
    return endpointResults
  }

  // Loop over each result from the endpoint
  return Object.keys(endpointResults).reduce((updatedObject, key) => {
    // If one of the key values contains another object, loop over that to
    // transform nested key values
    if (typeof endpointResults[key] === 'object') {
      return {
        ...updatedObject,
        [dataKeysMap[endpointKey][key] || key]: transformObjectKeys(
          key,
          endpointResults[key] as FieldType
        ),
      }
    }

    // Otherwise, just append the key value to the camelCase key
    return {
      ...updatedObject,
      [dataKeysMap[endpointKey][key] || key]: endpointResults[key],
    }
  }, {})
}

export const transformKeys: (data: {
  [endpointKey: string]: FieldType | FieldType[]
}) => DataType = (data) => {
  return Object.keys(data).reduce((transformedData, endpointKey) => {
    // Data from each endpoint is either an object, or an array of objects
    if (Array.isArray(data[endpointKey])) {
      const transformed = (data[endpointKey] as FieldType[]).map((item: FieldType) =>
        transformObjectKeys(endpointKey, item)
      )

      return { ...transformedData, [endpointKey]: transformed }
    } else if (typeof data[endpointKey] === 'object') {
      return {
        ...transformedData,
        [endpointKey]: transformObjectKeys(endpointKey, data[endpointKey] as FieldType),
      }
    }

    return transformedData
  }, {} as DataType)
}

export const filterEntitiesWithoutCoveringUser = <T extends { coveringUser: CoveringUser }>(
  entities: T[]
): T[] => entities.filter(({ coveringUser }) => !coveringUser.salesforceId)
