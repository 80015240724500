import styled from 'styled-components'

import { COLORS, MEDIA, ResponsiveValue, marginLeft, marginRight, P } from '@tourlane/tourlane-ui'

type StyledCounterProps = {
  $count: number | undefined
  $marginLeft?: number | ResponsiveValue
  $marginRight?: number | ResponsiveValue
}

const StyledCounter = styled.div<StyledCounterProps>`
  margin-left: 2px;
  margin-right: 16px;
  width: ${({ $count }) => ($count && $count > 99 ? '36px' : '20px')};
  height: 20px;
  border-radius: ${({ $count }) => ($count && $count > 99 ? '10px' : '50%')};
  background: ${COLORS.ELEMENT_GRAY};
  position: relative;

  ${({ $marginLeft }) => ($marginLeft ? marginLeft($marginLeft) : '')}
  ${({ $marginRight }) => ($marginRight ? marginRight($marginRight) : '')}

  ${MEDIA.from.md`
    width: ${({ $count }: { $count: number | undefined }) =>
      $count && $count > 99 ? '40px' : '24px'};
    height: 24px;
    border-radius: ${({ $count }) => ($count && $count > 99 ? '12px' : '50%')};
  `}
  
  p {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    color: ${COLORS.SENSATION_WHITE};
    flex-shrink: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute !important;

    ${MEDIA.from.md`
     font-size: 14px;
     line-height: 24px;
   `}
  }
`

export const BadgeCounter = ({
  count,
  marginLeft,
  marginRight,
}: {
  count: number | undefined
  marginLeft?: number | ResponsiveValue
  marginRight?: number | ResponsiveValue
}) => (
  <StyledCounter $count={count} $marginLeft={marginLeft} $marginRight={marginRight}>
    <P>{count}</P>
  </StyledCounter>
)
