import React from 'react'
import ReactDOM from 'react-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { SCOPE } from 'utils/api'
import { App } from 'components/App'
import { AppContextProvider } from 'components/AppContext'
import { OpportunityModalContextProvider } from 'components/OpportunityModalContext'
import { sentryInit } from 'utils/sentryApi'

sentryInit()

// Make sure redirect query param has our client id fragment
const skipRedirectCallback = () => {
  return window.location.hash !== `#${process.env.REACT_APP_AUTH0_CLIENT_ID}`
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      redirectUri={
        process.env.NODE_ENV === 'development'
          ? window.location.origin
          : `${process.env.REACT_APP_TOURLANE_SUITE_URL}${process.env.PUBLIC_URL}`
      }
      audience={process.env.REACT_APP_AUTH0_AUDIENCE_SUITE_API}
      scope={SCOPE}
      skipRedirectCallback={skipRedirectCallback()}
      cacheLocation="localstorage"
    >
      <AppContextProvider>
        <OpportunityModalContextProvider>
          <App />
        </OpportunityModalContextProvider>
      </AppContextProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
