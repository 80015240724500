import React from 'react'

import { Modal, Box, Hr, H5, Base, Flex, Button, COLORS, Hide } from '@tourlane/tourlane-ui'

import { formatPrice } from 'utils/helpers'
import { useOpportunityModalContext } from 'components/OpportunityModalContext'
import { PRESENT_SERVICE, SUPPLY_PENDING } from 'components/Pipeline/constants'
import { OpportunityModalHeader } from './OpportunityModalHeader'
import { OpportunityModalProgressBar as ProgressBar } from './OpportunityModalProgressBar'
import { OpportunityModalTasks as Tasks } from './OpportunityModalTasks'
import { OpportunityModalCustomerInfo as CustomerInfo } from './OpportunityModalCustomerInfo'
import { ModalSection } from './ModalSection'

type OpportunityModalProps = {
  isOpen: boolean
  close: () => void
}

export const OpportunityModal = ({ isOpen, close }: OpportunityModalProps) => {
  const { selectedOpportunity } = useOpportunityModalContext()

  if (!selectedOpportunity || !Object.keys(selectedOpportunity).length) return null

  const {
    stage,
    id,
    activeOffer: { offerMargin, tripPlannerUrl, internalWetuLink },
    price,
    answers,
    callNotesCustomer,
    callNotesTrip,
    docHandlingComments,
    revisionNotes,
    internalComments,
    offerPreviewId,
    offerPreviewLink,
  } = selectedOpportunity

  const shouldShowCustomerInfo = Boolean(
    answers ||
      callNotesCustomer ||
      callNotesTrip ||
      docHandlingComments ||
      revisionNotes ||
      internalComments
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      width={{
        xs: '100%',
        sm: 544,
        md: 720,
        lg: 742,
        xl: 800,
      }}
    >
      <Box overflow="auto" position="relative">
        <OpportunityModalHeader />

        <ModalSection pt={{ xs: 24, lg: 32, xl: 24 }}>
          <ProgressBar />
        </ModalSection>

        {stage === SUPPLY_PENDING && <Tasks opportunityId={id} />}

        {stage !== PRESENT_SERVICE && (offerMargin || price) && (
          <ModalSection>
            <Hr mb={{ xs: 24, lg: 32 }} />

            <Box mb={{ xs: 16, sm: 24 }}>
              <H5>Summary</H5>
            </Box>

            {offerMargin !== null && (
              <Flex alignItems="center" justifyContent="space-between" mb={16}>
                <Base strong>Margin</Base>
                <Base strong>{`${Math.round(offerMargin)}%`}</Base>
              </Flex>
            )}

            {price && (
              <Flex alignItems="center" justifyContent="space-between">
                <Base strong>Total</Base>
                <Base strong>{formatPrice(price)}</Base>
              </Flex>
            )}
          </ModalSection>
        )}

        {shouldShowCustomerInfo && <CustomerInfo />}
      </Box>

      {(stage === PRESENT_SERVICE || tripPlannerUrl || internalWetuLink) && (
        <Box
          position="sticky"
          bottom={0}
          left={0}
          right={0}
          backgroundColor={COLORS.SENSATION_WHITE}
        >
          <Hr />

          <Flex
            my={{ xs: 24, lg: 32 }}
            alignContent="center"
            justifyContent="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            fullWidth
            gap={24}
            px={16}
          >
            {stage === PRESENT_SERVICE && (
              <>
                <Button
                  href={`${process.env.REACT_APP_TOURLANE_SUITE_URL}/planner/trip-generator?opportunityId=${id}`}
                >
                  <Hide as="span" below="sm">
                    Go to&nbsp;
                  </Hide>
                  Trip Generator
                </Button>

                {offerPreviewId && (
                  <Button href={offerPreviewLink} target="_blank">
                    <Hide as="span" below="sm">
                      Go to&nbsp;
                    </Hide>
                    Offer Preview
                  </Button>
                )}
              </>
            )}

            {stage !== PRESENT_SERVICE && tripPlannerUrl && (
              <Button href={`${tripPlannerUrl}`}>Go to Trip Planner</Button>
            )}

            {stage !== PRESENT_SERVICE && internalWetuLink && (
              <Button href={`${internalWetuLink}`} target="_blank">
                Go to Trip Viz
              </Button>
            )}
          </Flex>
        </Box>
      )}
    </Modal>
  )
}
