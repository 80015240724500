import React from 'react'
import dayjs from 'dayjs'

import { Flex, Small, COLORS, useTooltip, ExtraSmall, Box, Dot } from '@tourlane/tourlane-ui'

import InfoIcon from '@tourlane/iconography/Glyphs/Notifications/Info'
import AlertIcon from '@tourlane/iconography/Glyphs/Notifications/Alert'

import { useOpportunityModalContext } from 'components/OpportunityModalContext'
import { OpportunityType } from 'components/AppContext/types'
import { INDICATOR_COLORS } from 'components/Pipeline/Opportunity'
import {
  PRESENT_SERVICE,
  SEND_OFFER,
  RECEIVE_CONFIRMATION,
  SUPPLY_PENDING,
  INDICATOR_GOOD,
  INDICATOR_MODERATE,
  INDICATOR_CRITICAL,
} from 'components/Pipeline/constants'
import { StyledChipWrapper, StyledIcon, StyledChip, StyledAlertIcon } from './styles'

const getPluralOrSingular = (number: number, measureOfTime: string, abbreviate?: boolean) => {
  if (measureOfTime === 'days') {
    if (abbreviate) return `${number}d`
    return number === 1 ? `${number} day` : `${number} days`
  } else if (measureOfTime === 'hours') {
    if (abbreviate) return `${number}h`
    return number === 1 ? `${number} hour` : `${number} hours`
  }
  return number
}

const formatDuration = (hours: number) => {
  if (hours >= 24 || hours <= -24) {
    const days = Math.floor(hours / 24)
    const hoursLeft = hours % 24
    if (!hoursLeft || days > 1) return getPluralOrSingular(days, 'days')

    return `${hours < 0 ? '-' : ''}${getPluralOrSingular(Math.abs(days), 'days', true)} ${
      hoursLeft ? getPluralOrSingular(Math.abs(hoursLeft), 'hours', true) : ''
    }`
  }
  return getPluralOrSingular(hours, 'hours')
}

const getStatusInfo = (selectedOpportunity: OpportunityType) => {
  const {
    stageSince,
    offerCallTime,
    firstCallTime,
    totalCallAttempts,
    stage,
    indicator,
  } = selectedOpportunity

  const today = dayjs()

  const daysInStage = stageSince ? today.diff(dayjs(stageSince), 'day') : 0
  const timeToOfferCall = offerCallTime ? dayjs(offerCallTime).diff(today, 'hour') : 0
  const timeAfterFirstCall = firstCallTime ? today.diff(dayjs(firstCallTime), 'hour') : 0
  const callAttempts = totalCallAttempts ?? 0

  let indicatorTooltip

  if (stage === PRESENT_SERVICE) {
    if (indicator === INDICATOR_CRITICAL) {
      indicatorTooltip = 'Stage duration 10+d or 8+ call attempts'
    } else if (indicator === INDICATOR_MODERATE) {
      indicatorTooltip = 'Stage duration 5-10d or 4-7 call attempts'
    } else if (indicator === INDICATOR_GOOD) {
      indicatorTooltip = 'Stage duration < 5 days or < 4 call attempts'
    }

    return {
      indicatorText: `Stage duration: ${getPluralOrSingular(
        daysInStage,
        'days'
      )} | Call attempts: ${callAttempts}`,
      indicatorTooltip,
    }
  }

  if (stage === SEND_OFFER) {
    if (indicator === INDICATOR_CRITICAL) {
      indicatorTooltip = '< 24h to Offer Call or > 96h delta to First Call done'
    } else if (indicator === INDICATOR_MODERATE) {
      indicatorTooltip = '24h - 48h to Offer Call or 48h - 96h to First Call done'
    } else if (indicator === INDICATOR_GOOD) {
      indicatorTooltip = '> 48h to Offer Call or < 48h delta to First Call done'
    }

    return {
      indicatorText: `Offer call: ${formatDuration(
        timeToOfferCall
      )} | Delta to first call done: ${formatDuration(timeAfterFirstCall)}`,
      indicatorTooltip,
    }
  }

  if (stage === RECEIVE_CONFIRMATION) {
    if (indicator === INDICATOR_CRITICAL) {
      indicatorTooltip = 'Stage duration > 14d or 8+ call attempts'
    } else if (indicator === INDICATOR_MODERATE) {
      indicatorTooltip = 'Stage duration 7-14d or 4-7 call attempts'
    } else if (indicator === INDICATOR_GOOD) {
      indicatorTooltip = 'Stage duration < 7d or < 4 call attempts'
    }

    return {
      indicatorText: `Stage duration: ${getPluralOrSingular(
        daysInStage,
        'days'
      )} | Call attempts: ${callAttempts}`,

      indicatorTooltip,
    }
  }

  if (stage === SUPPLY_PENDING) {
    if (indicator === INDICATOR_GOOD) {
      indicatorTooltip = 'Stage duration < 14d'
    } else if (indicator === INDICATOR_MODERATE) {
      indicatorTooltip = 'Stage duration is between 14d and 21d'
    } else if (indicator === INDICATOR_CRITICAL) {
      indicatorTooltip = 'Stage duration > 21d'
    }

    return {
      indicatorText: `Pending: ${getPluralOrSingular(daysInStage, 'days')}`,
      indicatorTooltip,
    }
  }

  return {
    indicatorText: null,
    indicatorTooltip: null,
  }
}

export const OpportunityModalStatus = () => {
  const { selectedOpportunity } = useOpportunityModalContext()
  const {
    indicator,
    activeOffer: { departureDate },
  } = selectedOpportunity

  // We have to check for and use null since 0 is not falsy for our use case
  const daysToDeparture = departureDate !== null ? dayjs(departureDate).diff(dayjs(), 'day') : null

  const { indicatorTooltip, indicatorText } = getStatusInfo(selectedOpportunity)

  const { setRef, portalNode } = useTooltip({
    content: indicatorTooltip ? <ExtraSmall>{indicatorTooltip}</ExtraSmall> : null,
  })

  return (
    <Flex
      alignItems={{ xs: 'flex-start', md: 'center' }}
      mr={{ xs: 48, sm: '50%', md: 48 }}
      mt={{ xs: 4, sm: 8 }}
      mb={8}
    >
      <Box mr={8}>
        <Dot color={INDICATOR_COLORS[indicator]} />
      </Box>
      {indicatorText && <Small muted>{indicatorText}</Small>}

      <Box ml={8}>
        <StyledIcon
          size={16}
          color={COLORS.ELEMENT_GRAY}
          colorHover={COLORS.ADVENTURE_GREEN}
          ref={setRef}
        >
          <InfoIcon />
          {portalNode}
        </StyledIcon>
      </Box>

      {daysToDeparture !== null && daysToDeparture <= 30 && (
        <StyledChipWrapper>
          <StyledChip iconLeft={<AlertIcon />} iconLeftColor={COLORS.RIOJA_RED} iconLeftSize={24}>
            {`${daysToDeparture} days to departure`}
          </StyledChip>
          <StyledAlertIcon size={{ xs: 20, sm: 0 }} color={COLORS.RIOJA_RED}>
            <AlertIcon />
          </StyledAlertIcon>
        </StyledChipWrapper>
      )}
    </Flex>
  )
}
