export const CALLS_HEIGHT = {
  xs: 374,
  sm: 420,
  md: 470,
  lg: 476,
  xl: 492,
}

export const MY_DAY_HEIGHT = {
  xs: 440,
  sm: 460,
  md: 470,
  lg: 476,
  xl: 492,
}

export const PIPELINE_HEIGHT = {
  xs: 546,
  md: 568,
  lg: 584,
  xl: 622,
}
