export type CustomerType = {
  internalName: string
  firstName: string
  lastName: string
  salesforceId: string
}

export type CoveringUser = {
  salesforceId: string | null
  transferTypeOfLeave: string
  coverFirstName: string
  userName: string
  coverLastName: string
}

export type TransferredUser = {
  salesforceId: string | null
  transferFirstName: string | null
  userName: string | null
  transferLastName: string | null
}

export type CallsDataType = {
  coveringUser: CoveringUser
  salesforceId: string
  callType: string
  dateAndTime: string
  status: string
  ownerId: string
  opportunity: CustomerType
  transferredUser: TransferredUser
}

export type OpportunityType = {
  id: string
  name: string
  firstName: string
  lastName: string
  destination: string
  nextCallTime: string
  nextCallType: string
  price: number | null
  latestCallAttempt: string | null
  totalCallAttempts: number | null
  stage: string
  opportunityOwner: string
  priority: string | null
  leadSource: string
  answers: string
  internalComments: string
  callNotesTrip: string
  callNotesCustomer: string
  docHandlingComments: string
  revisionNotes: string
  stageSince: string | null
  isNoShowCall: boolean
  firstCallTime: string | null
  offerCallTime: string | null
  coveringUser: CoveringUser
  transferredUser: TransferredUser
  isPreConfirmed: boolean
  account: {
    specialCustomerType: string
    accountOwner: string
    phoneNumber: string
  }
  activeOffer: {
    tripPlannerUrl: string | null
    offerMargin: number | null
    internalWetuLink: string | null
    paxCount: number | null
    departureDate: string | null
    arrivalDate: string | null
  }
  indicator: string
  offerPreviewId: string
  offerPreviewLink: string
}

export type TaskType = {
  coveringUser: CoveringUser
  dueDate: string
  recordType: string
  salesforceId: string
  subject: string
  transferredUser: TransferredUser
}

export type DataType = {
  calls: CallsDataType[]
  pipeline: OpportunityType[]
  tasks: TaskType[]
  tripsSold: TripsSoldType[]
}

export type TripsSoldType = {
  salesforceId: string
  destination: string
  price: number
  dateCreated: string
  user: {
    salesforceId: string
    firstName: string
    lastName: string
  }
}

export enum OwnedBy {
  Anyone = 'anyone',
  Me = 'me',
}
