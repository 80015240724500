// The scope of the Suite API.
export const SCOPE =
  'calls:initiate dashboard:read:calls dashboard:read:tasks dashboard:read:pipeline dashboard:read:cr dashboard:read:trips_sold dashboard:read:time_off'

// The prefix of all the endpoints of the Suite API.
export const API_PREFIX = `${process.env.REACT_APP_TOURLANE_SUITE_URL}/dashboard/api`

// Token management service
class TokenManager {
  token = ''

  setToken = (token: string) => {
    this.token = token
  }

  getToken = () => {
    return this.token
  }
}

const tokenManager = new TokenManager()
export { tokenManager }
