import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import {
  ListItemLink,
  Chip,
  COLORS,
  Tooltip,
  Small,
  IconDefault,
  H5,
  Box,
  Hr,
  MEDIA,
} from '@tourlane/tourlane-ui'
import AlertIcon from '@tourlane/iconography/Glyphs/Notifications/Alert'

import { tokenManager } from 'utils/api'
import { request } from 'utils/request'
import { isInPast } from 'utils/helpers'
import { transformObjectKeys, FieldType } from 'components/AppContext/utils'
import { TaskType } from 'components/AppContext/types'

const StyledListItemLink = styled(ListItemLink)`
  width: 100%;

  ${MEDIA.from.sm`
    padding-left: 24px;
    padding-right: 24px;
  `}

  ${MEDIA.from.md`
    padding-left: 32px;
    padding-right: 32px;
  `}
`

export const OpportunityModalTasks = ({ opportunityId }: { opportunityId: string }) => {
  const [tasks, setTasks] = useState<TaskType[]>([])

  useEffect(() => {
    const getOpportunityTasks = async () => {
      const accessToken = tokenManager.getToken()

      try {
        const data = await request({
          url: `${process.env.REACT_APP_TOURLANE_SUITE_URL}/dashboard/api/opportunities/${opportunityId}/tasks`,
          accessToken,
        })
        const updatedTasks = data.map((item: FieldType) => transformObjectKeys('tasks', item))
        setTasks(updatedTasks)
      } catch (err) {
        const { error } = err.json()
        return console.error(error)
      }
    }

    getOpportunityTasks()
  }, [opportunityId, setTasks])

  if (!tasks.length) {
    return null
  }

  return tasks.length ? (
    <Box pb={8}>
      <Box mb={16} px={{ xs: 16, sm: 24, md: 32 }}>
        <Hr mb={{ xs: 24, lg: 32 }} />
        <H5>Tasks</H5>
      </Box>

      {tasks.map(({ salesforceId, subject, dueDate }: TaskType) => (
        <StyledListItemLink
          titleStrong={isInPast(dueDate)}
          key={subject}
          title={subject}
          subline={dayjs(dueDate).format('HH:mm DD.MM.YY')}
          chip={
            isInPast(dueDate) && (
              <Chip
                iconLeft={<AlertIcon />}
                iconLeftColor={COLORS.RIOJA_RED}
                iconLeftSize={20}
                size="small"
              >
                Overdue
              </Chip>
            )
          }
          icon={
            isInPast(dueDate) && (
              <Tooltip flex content={<Small as="span">Overdue</Small>}>
                <IconDefault
                  icon={<AlertIcon />}
                  color={COLORS.RIOJA_RED}
                  colorHover={COLORS.RIOJA_RED_FOCUSED}
                />
              </Tooltip>
            )
          }
          onClick={() =>
            window.location.assign(
              `${process.env.REACT_APP_TOURLANE_SUITE_SF_URL}/s/task/${salesforceId}`
            )
          }
        />
      ))}
    </Box>
  ) : null
}
