import dayjs from 'dayjs'

export const isProduction = process.env.NODE_ENV === 'production'

export const getLocalStorageKey = (key: string) =>
  window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key) as string) : null
export const setLocalStorageKey = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value))

export const isInPast = (time: string) => {
  // Check if time is before "now"
  return dayjs(time).isBefore(dayjs(), 'minutes')
}

export const formatPrice = (price: number) =>
  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)
