import React, { useEffect, useMemo, useState } from 'react'

import { Base, CardsCarousel, Box, H5 } from '@tourlane/tourlane-ui'

import { useModal } from 'hooks/useModal'
import { OpportunityType } from 'components/AppContext/types'
import { formatPrice } from 'utils/helpers'

import { useAppContext } from 'components/AppContext'
import { Card } from 'components/Card'
import { Section } from 'components/Section'
import { OpportunityModal } from 'components/OpportunityModal'
import { PIPELINE_HEIGHT } from 'components/PageGrid/styles'
import { useOpportunityModalContext } from 'components/OpportunityModalContext'
import { getCurrentPipeline } from 'components/AppContext/selectors'
import { Opportunity } from './Opportunity'
import { Filter } from './Filter'

import { normalizePipelineOffers } from './normalize'

import {
  StyledColHeader,
  StyledOppsSliderWrapper,
  StyledStageTitle,
  StyledOppsWrapper,
  StyledStageCounter,
} from './styles'

type StageType = {
  [stageType: string]: {
    items: OpportunityType[]
    count: number
    totalPrice: number
    stage: string
  }
}

const Pipeline = () => {
  const [opportunities, setOpportunities] = useState<StageType>({})
  const [filters, setFilters] = useState<string[]>([])
  const {
    data: { pipeline },
    ownedBy,
  } = useAppContext()
  const currentPipeline = useMemo(() => getCurrentPipeline({ data: { pipeline }, ownedBy }), [
    pipeline,
    ownedBy,
  ])
  const { isOpen, open, close } = useModal()
  const { setSelectedOpportunity } = useOpportunityModalContext()

  useEffect(() => {
    if (currentPipeline.length) {
      setOpportunities(normalizePipelineOffers(currentPipeline, filters))
    }
  }, [currentPipeline, filters])

  const handleModalOpen = (opportunity: OpportunityType) => {
    // clear previously selected opp
    setSelectedOpportunity({} as OpportunityType)
    // set newly selected opp
    setSelectedOpportunity(opportunity)
    // open modal
    open()
  }

  if (!Object.keys(opportunities).length) return null

  return (
    <div data-testid="Pipeline">
      <Section
        header={
          <Filter
            updateFilters={(newFilters) => setFilters(newFilters)}
            itemsCount={currentPipeline.length}
          />
        }
      >
        <Card height={PIPELINE_HEIGHT} scroll>
          <StyledOppsSliderWrapper>
            <CardsCarousel
              numberOfCards={{ xs: 1.3, sm: 2.2, md: 2.7, lg: 4 }}
              showChevrons={{ xs: false }}
              disableSwipe={false}
              gutter={0}
            >
              {Object.keys(opportunities).map((key: string) => (
                <div key={key}>
                  <StyledColHeader alignItems="center" justifyContent="between">
                    <Box mr={{ xs: 10 }}>
                      <StyledStageTitle bold ellipsis>
                        {key}
                      </StyledStageTitle>
                      <Base muted>
                        {opportunities[key].totalPrice
                          ? formatPrice(opportunities[key].totalPrice)
                          : '-'}
                      </Base>
                    </Box>
                    <StyledStageCounter count={opportunities[key].count} />
                  </StyledColHeader>
                  <StyledOppsWrapper>
                    {opportunities[key].items.length ? (
                      opportunities[key].items.map((opportunity: OpportunityType) => (
                        <Opportunity
                          key={opportunity.id}
                          opportunity={opportunity}
                          handleModalOpen={handleModalOpen}
                          stage={opportunities[key].stage}
                        />
                      ))
                    ) : (
                      <Box my={20}>
                        <H5>No opportunities</H5>
                      </Box>
                    )}
                  </StyledOppsWrapper>
                </div>
              ))}
            </CardsCarousel>
          </StyledOppsSliderWrapper>
        </Card>
      </Section>
      <OpportunityModal isOpen={isOpen} close={close} />
    </div>
  )
}

export default Pipeline
