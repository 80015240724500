import React from 'react'
import styled from 'styled-components'

import {
  SvgIcon,
  Flex,
  Box,
  Link,
  Base,
  Small,
  ExtraSmall,
  ResponsiveValue,
} from '@tourlane/tourlane-ui'
import ErrorIcon from '@tourlane/iconography/Icons/Error/Error'

type Props = {
  size: string
  isCompanyData?: boolean
}

type SizeType = {
  [key: string]: ResponsiveValue
}

const ICON_SIZES: SizeType = {
  sm: {
    xs: 134,
    sm: 148,
    md: 110,
    lg: 118,
  },
  md: {
    xs: 134,
    sm: 148,
  },
  lg: {
    xs: 134,
    sm: 148,
    lg: 180,
  },
}

const TEXT_WIDTH: SizeType = {
  xs: null,
  sm: {
    sm: 420,
    md: 300,
    lg: 440,
  },
  md: {
    sm: 420,
    md: 620,
    lg: 380,
  },
  lg: {
    sm: 420,
    md: 620,
    lg: 748,
  },
}

const StyledLink = styled(Link)`
  white-space: nowrap;
`

export const ErrorState = ({ size, isCompanyData }: Props) => {
  const Text = size === 'xs' ? ExtraSmall : size === 'sm' ? Small : Base

  if (size === 'xs')
    return (
      <Flex direction="column" fullHeight alignItems="center" center>
        <Box mx={16}>
          <Text>
            We cannot fetch {isCompanyData ? 'the' : 'your'} data, please reload the page. If the
            issue persists, contact{' '}
            <StyledLink href="https://tourlane.slack.com/archives/C730WFNKC" target="blank">
              this&nbsp;channel
            </StyledLink>
            .
          </Text>
        </Box>
      </Flex>
    )

  return (
    <Flex direction="column" fullHeight alignItems="center" center>
      <Box mb={{ xs: 2, lg: 10 }}>
        <SvgIcon size={ICON_SIZES[size]}>
          <ErrorIcon />
        </SvgIcon>
      </Box>
      <Box mx={16} maxWidth={TEXT_WIDTH[size]}>
        <Text textAlign="center">
          We cannot fetch {isCompanyData ? 'the' : 'your'} data currently. Please reload
          the&nbsp;page. If the problem persists for 5&nbsp;minutes, please reach out to&nbsp;the{' '}
          <StyledLink href="https://tourlane.slack.com/archives/C730WFNKC" target="blank">
            #support-operations
          </StyledLink>{' '}
          channel.
        </Text>
      </Box>
    </Flex>
  )
}
