export const request = async ({
  url,
  accessToken,
  options = {},
}: {
  url: string
  accessToken?: string
  options?: RequestInit
}) => {
  const initObject = { ...options }

  // If an accessToken is provided, add the Authorization header to the existing headers.
  if (accessToken) {
    initObject.headers = {
      ...initObject.headers,
      Authorization: `Bearer ${accessToken}`,
    }
  }

  const response = await fetch(url, initObject)

  return response.ok ? response.json() : Promise.reject(response)
}
