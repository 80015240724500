import React from 'react'
import styled from 'styled-components'
import { Card as TUICard, COLORS, ResponsiveValue } from '@tourlane/tourlane-ui'

const StyledHeader = styled.div`
  border-bottom: 1px solid ${COLORS.LINE_GRAY};
`

type StyledTUICardProps = {
  $flex: boolean | undefined
  $scroll: boolean | undefined
}
const StyledTUICard = styled(TUICard)<StyledTUICardProps>`
  border-radius: 10px;
  ${({ $scroll }) => $scroll &&
    `
    overflow-y: auto;
  `}
  ${({ $flex }) =>
    $flex &&
    `
    display: flex;
    flex-direction: column;
  `}
`

type CardProps = {
  children: any
  header?: React.ReactElement
  width?: ResponsiveValue
  height?: ResponsiveValue
  flex?: boolean
  scroll?: boolean
}

export const Card = ({ children, header, flex, scroll, ...props }: CardProps) => {
  return (
    <StyledTUICard {...props} $flex={flex} $scroll={scroll}>
      {header && <StyledHeader>{header}</StyledHeader>}
      {children}
    </StyledTUICard>
  )
}
