import * as Sentry from '@sentry/browser'
const environment = process.env.REACT_NODE_ENV || 'development'
const shouldTrackSentry = ['production', 'staging'].includes(environment)

/**
 * Start sentry monitoring.
 */
export const sentryInit = () => {
  if (!shouldTrackSentry) {
    return
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_REVISION,
    environment,
    blacklistUrls: ['localhost'],
  })
}
