import React from 'react'
import styled from 'styled-components'
import { GlobalStyle } from 'components/GlobalStyle'
import { PageGrid } from 'components/PageGrid'

// @ts-ignore: Using web components in JSX triggers a TS2339 warning.
const TourlaneSuiteSidebar = () => <suite-menu itemselected="dashboard" />

const AppWrapper = styled.div`
  height: 100vh;
  width: 100%;
`

const App = () => (
  <AppWrapper data-testid="app">
    <GlobalStyle />
    <TourlaneSuiteSidebar />
    <PageGrid />
  </AppWrapper>
)

export default App
