import styled from 'styled-components'
import { Base, Card, Chip, COLORS, Flex, H5, MEDIA } from '@tourlane/tourlane-ui'

import { BadgeCounter } from 'components/BadgeCounter'

export const StyledColHeader = styled(Flex)`
  border-bottom: 1px solid ${COLORS.LINE_GRAY};
  padding: 16px;
  margin: 0 -16px 16px 0;
  ${MEDIA.from.md`
    padding: 16px 24px;
    margin: 0 -24px 24px 0;
  `}
`

export const StyledFiltersWrapper = styled(Flex)`
  ${MEDIA.below.sm`
    width: 100%;
    margin-top: 8px;
  `}
`

export const StyledFiltersChip = styled(Chip)`
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }

  ${MEDIA.only.xs`
    margin-top: 8px;
    margin-bottom: 8px;
  `}
  ${MEDIA.only.sm`
    margin-top: 18px;
    margin-bottom: 18px;
  `}
`

export const StyledStageTitle = styled(Base)`
  ${MEDIA.from.xs`
    margin-bottom: 4px;
  `}
  ${MEDIA.from.sm`
    margin-bottom: 0;
  `}
`

export const StyledStageCounter = styled(BadgeCounter)`
  flex-grow: 0;
  flex-shrink: 0;
`

export const StyledOppsSliderWrapper = styled.div`
  overflow: hidden;
  ${MEDIA.from.xs`
    padding-right: 16px;
  `}
  ${MEDIA.from.md`
    padding-right: 24px;
  `}
`

export const StyledOppsWrapper = styled.div`
  ${MEDIA.from.xs`
    padding-left: 16px;
  `}
  ${MEDIA.from.md`
    padding-left: 24px;
  `}
`

export const StyledOppColHeader = styled.div`
  overflow: hidden;
`

const overflowStyles = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledOppColName = styled(H5)`
  ${overflowStyles}
`

export const StyledOppIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 2px;
`
export const StyledOppCard = styled(Card)`
  cursor: pointer;
  margin-bottom: 16px;

  ${MEDIA.from.md`
    margin-bottom: 24px;
  `}
`

