export const NO_SHOW_ID = 'no_show_call'
export const HOT_OPP = 'Hot'
export const WARM_OPP = 'Warm'
export const COLD_OPP = 'Cold'
export const NOT_CLASSIFIED = 'Not classified'

export const CUSTOMER_TYPE_TOURLANE_PLUS = 'TourlanePlus'
export const CUSTOMER_TYPE_REPEATER = 'Repeater'

export const VACATION_LEAVE = 'Holiday'
export const SICK_LEAVE = 'Sick'

export const PRESENT_SERVICE = 'Present Service'
export const SEND_OFFER = 'Send Offer'
export const RECEIVE_CONFIRMATION = 'Receive Confirmation'
export const SUPPLY_PENDING = 'Confirmed - Supply Pending'
export const INDICATOR_GOOD = 'good'
export const INDICATOR_MODERATE = 'moderate'
export const INDICATOR_CRITICAL = 'critical'
