import React from 'react'
import dayjs from 'dayjs'

import { ModalHeader, Ul, PillButton, Flex, Box } from '@tourlane/tourlane-ui'
import LocationIcon from '@tourlane/iconography/Glyphs/Navigation/Location'
import CalendarIcon from '@tourlane/iconography/Glyphs/Accommodation/Calendar'
import UserIcon from '@tourlane/iconography/Glyphs/Navigation/User'
import PhoneIcon from '@tourlane/iconography/Glyphs/Navigation/Phone'

import { useOpportunityModalContext } from 'components/OpportunityModalContext'
import { OpportunityModalStatus } from './OpportunityModalStatus'
import { StyledH3, StyledLiWithIcon } from './styles'

export const OpportunityModalHeader = () => {
  const { selectedOpportunity } = useOpportunityModalContext()
  const {
    firstName,
    lastName,
    destination,
    nextCallTime,
    activeOffer: { departureDate, arrivalDate, paxCount },
    account,
    id,
    name,
  } = selectedOpportunity

  const callCustomer = (phoneNumber: string) => {
    const msg = JSON.stringify({ action: 'triggerCall', phone: phoneNumber })
    window.postMessage(msg, window.origin)
  }

  return (
    <ModalHeader>
      <OpportunityModalStatus />

      <StyledH3>{`${firstName} ${lastName}`}</StyledH3>

      <Ul withIcons inline>
        {destination && <StyledLiWithIcon icon={<LocationIcon />}>{destination}</StyledLiWithIcon>}

        {departureDate && arrivalDate && (
          <StyledLiWithIcon
            icon={<CalendarIcon />}
          >{`${departureDate} - ${arrivalDate}`}</StyledLiWithIcon>
        )}

        {paxCount && <StyledLiWithIcon icon={<UserIcon />}>{paxCount}</StyledLiWithIcon>}

        {nextCallTime && (
          <StyledLiWithIcon icon={<PhoneIcon />}>
            {dayjs(nextCallTime).format('DD.MM.YY, HH:mm')}
          </StyledLiWithIcon>
        )}
      </Ul>

      <Flex
        fullWidth
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
        mt={{ xs: 10, md: 0 }}
        mb={{ xs: 10, md: 4 }}
      >
        <Box mr={16}>
          <PillButton
            variant="primary"
            size="small"
            onClick={() => callCustomer(account.phoneNumber)}
          >
            Call customer
          </PillButton>
        </Box>
        <PillButton
          variant="secondary"
          size="small"
          onClick={() =>
            window.location.assign(
              `${process.env.REACT_APP_TOURLANE_SUITE_SF_URL}/s/opportunity/${id}/${name}`
            )
          }
        >
          Go to opportunity
        </PillButton>
      </Flex>
    </ModalHeader>
  )
}
