import { useCallback, useState } from 'react'

export const useModal = ({ isOpen: isOpenInitial = false } = {}) => {
  const [isOpen, setOpen] = useState(isOpenInitial)
  const close = useCallback(() => setOpen(false), [])
  const open = useCallback(() => setOpen(true), [])

  return {
    isOpen,
    setOpen,
    close,
    open,
  }
}
