import React from 'react'
import styled from 'styled-components'

import { ExtraSmall, Toggle, ToggleLabel, MEDIA } from '@tourlane/tourlane-ui'
import { useAppContext } from 'components/AppContext'
import { getOwnedByAnyoneFlag } from 'components/AppContext/selectors'

const Line = styled.span`
  display: block;

  ${MEDIA.to.sm`
    display: inline;
  `}
`

export const OwnedByToggle = ({ className }: { className?: string }) => {
  const {
    actions: { toggleOwnedBy },
    ...state
  } = useAppContext()
  const areOwnedByAnyone = getOwnedByAnyoneFlag(state)

  return (
    <ToggleLabel className={className} data-testid="owned-by-toggle" position="right">
      <ExtraSmall>
        <Line>Include vacation &amp;</Line>
        <Line> sick leave coverage</Line>
      </ExtraSmall>
      <Toggle checked={areOwnedByAnyone} onChange={toggleOwnedBy} />
    </ToggleLabel>
  )
}
