import React, { SVGProps } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'

import {
  Card,
  COLORS,
  Container,
  Flex,
  Hr,
  InfoWithIconsSingle,
  LiWithIcon,
  MEDIA,
  PriceBase,
  SvgIcon,
  Tooltip,
  Ul,
  Dot,
  Hide,
  ExtraSmall,
  useDeviceType,
} from '@tourlane/tourlane-ui'
import WebsiteIcon from '@tourlane/iconography/Glyphs/Navigation/Website'
import PhoneIcon from '@tourlane/iconography/Glyphs/Navigation/Phone'
import NoShow from '@tourlane/iconography/Glyphs/Suite/NoShow'
import Checkmark from '@tourlane/iconography/Glyphs/Other/Checkmark'

import { OpportunityType } from 'components/AppContext/types'
import { formatPrice } from 'utils/helpers'
import { INDICATOR_GOOD, INDICATOR_MODERATE, INDICATOR_CRITICAL, SUPPLY_PENDING } from './constants'

import { OpportunityIcons } from './OpportunityIcons'

const StyledOppCard = styled(Card)`
  cursor: pointer;
  margin-bottom: 16px;

  ${MEDIA.from.md`
    margin-bottom: 24px;
  `}
`

type IndicatorColorsType = {
  [key: string]: string
}

export const INDICATOR_COLORS: IndicatorColorsType = {
  [INDICATOR_GOOD]: COLORS.ADVENTURE_GREEN,
  [INDICATOR_MODERATE]: COLORS.SUNSHINE_YELLOW,
  [INDICATOR_CRITICAL]: COLORS.RIOJA_RED_FOCUSED,
}

type IconWithTooltipProps = {
  tooltipText: string
  Icon: React.FC<SVGProps<SVGSVGElement>>
  iconSize?: number
}

const IconWithTooltip = ({ tooltipText, Icon, iconSize }: IconWithTooltipProps) => {
  const { hasTouchScreen } = useDeviceType()

  return (
    <Tooltip content={tooltipText} size="small" disabled={hasTouchScreen}>
      <SvgIcon color={COLORS.ELEMENT_GRAY} colorHover={COLORS.ADVENTURE_GREEN} size={iconSize}>
        <Icon />
      </SvgIcon>
    </Tooltip>
  )
}

type OpportunityProps = {
  opportunity: OpportunityType
  handleModalOpen: (opportunity: OpportunityType) => void
  stage: string
}

export const Opportunity = ({ opportunity, handleModalOpen, stage }: OpportunityProps) => {
  const {
    destination,
    isNoShowCall,
    price,
    nextCallTime,
    isPreConfirmed,
    activeOffer: { departureDate },
    indicator,
  } = opportunity

  // We have to check for and use null since 0 is not falsy for our use case
  const daysToDeparture = departureDate !== null ? dayjs(departureDate).diff(dayjs(), 'day') : null

  return (
    <StyledOppCard
      onClick={() => handleModalOpen(opportunity)}
      withHover
      shadowVariant={
        daysToDeparture !== null && daysToDeparture <= 30
          ? daysToDeparture < 14
            ? 'alarm'
            : 'warning'
          : 'default'
      }
      borderColor={
        daysToDeparture !== null && daysToDeparture <= 30
          ? daysToDeparture < 14
            ? COLORS.RIOJA_RED
            : COLORS.SUNSHINE_YELLOW
          : null
      }
    >
      <Container py={0} px={1 / 2}>
        <Container py={1 / 2} px={0}>
          <OpportunityIcons opportunity={opportunity} />
        </Container>
        <Container py={1 / 2} px={0}>
          <Flex justifyContent="space-between" alignItems="end">
            <Ul withIcons>
              <LiWithIcon icon={<WebsiteIcon />}>{destination}</LiWithIcon>
              <LiWithIcon
                icon={
                  nextCallTime ? (
                    <IconWithTooltip
                      tooltipText={`Time of call: ${dayjs(nextCallTime).format('HH:mm')}`}
                      Icon={isNoShowCall ? NoShow : PhoneIcon}
                    />
                  ) : isNoShowCall ? (
                    <NoShow />
                  ) : (
                    <PhoneIcon />
                  )
                }
              >
                {nextCallTime ? dayjs(nextCallTime).format('DD.MM.YY') : '-'}
              </LiWithIcon>
            </Ul>
            {isPreConfirmed && stage !== SUPPLY_PENDING && (
              <>
                <Hide from="sm" to="lg">
                  <ExtraSmall>Pre-confirmed</ExtraSmall>
                </Hide>
                <Hide from="xl">
                  <IconWithTooltip Icon={Checkmark} tooltipText="Pre-confirmed" iconSize={24} />
                </Hide>
              </>
            )}
          </Flex>
        </Container>
        <Hr />
        <Container py={1 / 2} px={0}>
          <InfoWithIconsSingle
            fullWidth
            fullInnerWidth
            ellipsis
            iconRight={<Dot color={INDICATOR_COLORS[indicator]} />}
          >
            <PriceBase>{price ? formatPrice(price) : '-'}</PriceBase>
          </InfoWithIconsSingle>
        </Container>
      </Container>
    </StyledOppCard>
  )
}
