import styled from 'styled-components'

import { H3, LiWithIcon, MEDIA, Flex, COLORS, Icon, Chip } from '@tourlane/tourlane-ui'

export const StyledLiWithIcon = styled(LiWithIcon)`
  margin-bottom: 10px;

  ${MEDIA.from.md`
    margin-bottom: 16px;
  `}
`
export const StyledH3 = styled(H3)`
  margin-bottom: 12px;

  ${MEDIA.from.md`
    margin-bottom: 18px;
  `}

  ${MEDIA.only.lg`
    margin-bottom: 12px;
  `}
`

export const StyledFlex = styled(Flex)`
  position: absolute;
  z-index: 2;
  left: 0;
  top: -9px;

  ${MEDIA.from.sm`
    top: -11px;
  `}
`

export const StyledWrapper = styled(Flex)<{ $isActive: boolean }>`
  background-color: ${({ $isActive }) => ($isActive ? COLORS.ADVENTURE_GREEN : COLORS.LINE_GRAY)};
  border-radius: 50%;
  width: 24px;
  height: 24px;

  ${MEDIA.from.sm`
    width: 30px;
    height: 30px;
  `}
`

export const StyledTextWrapper = styled(Flex)`
  display: none;

  ${MEDIA.from.sm`
    display: flex;
  `}
`

export const StyledChipWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 44px;

  ${MEDIA.from.md`
    top: 16px;
    right: 48px;
  `}

  ${MEDIA.from.lg`
    right: 56px;
  `}
  
  ${MEDIA.from.lg`
    top: 18px;
  `}
`

export const StyledChip = styled(Chip)`
  ${MEDIA.below.sm`
    display: none;
  `}
`

export const StyledIcon = styled(Icon)`
  ${MEDIA.below.lg`
    display: none;
  `}
`

export const StyledAlertIcon = styled(Icon)`
  ${MEDIA.above.xs`
    display: none;
  `}
`

export const StyledLeadQualIcon = styled(Icon)`
  margin-bottom: 16px;
`
export const StyledLeadQualFlex = styled(Flex)`
  width: 150px;

  ${MEDIA.only.xs`
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  `}

  ${MEDIA.only.sm`  
    &:first-child, &:nth-child(3) {
      margin-right: 24px;
    }
    
    &:first-child, &:nth-child(2) {
      margin-bottom: 24px;
    }
  `}

  ${MEDIA.from.md`
    margin-right: 16px;
  
    &:last-child {
      margin-right: 0;
    }
  `}

  ${MEDIA.from.xl`
    width: 160px;
  `}
`
