import { createContext, useContext, useState } from 'react'

import { OpportunityType } from 'components/AppContext/types'

type Props = { children: any }

export const OpportunityModalContext = createContext<{
  selectedOpportunity: OpportunityType
  setSelectedOpportunity: React.Dispatch<React.SetStateAction<OpportunityType>>
}>({} as any)

export const useOpportunityModalContext = () => useContext(OpportunityModalContext)

export const OpportunityModalContextProvider = ({ children }: Props) => {
  const [selectedOpportunity, setSelectedOpportunity] = useState({} as OpportunityType)

  return (
    <OpportunityModalContext.Provider value={{ selectedOpportunity, setSelectedOpportunity }}>
      {children}
    </OpportunityModalContext.Provider>
  )
}
